import { ActionManager } from 'utils/action_manager';
import * as stringUtils from 'utils/string';

export const badgeTemplateFilterOptionsActions =
  (new ActionManager('managementOrganizationsBadgeTemplatesFilterOptions'))
    .add({
      requestKey: 'get',
      effect: 'read',
      method: 'GET',
      url: '/api/v1/organizations/[id]/badge_templates/filters'
    });

export const badgeTemplateSearchActions = (new ActionManager('managementOrganizationsBadgeTemplatesSearch'))
  .add({
    requestKey: 'templateNames',
    urlSubs: new stringUtils.BracketSubs({
      organization_id: { param: 'organization_id', remove: true }
    }),
    effect: 'read',
    method: 'GET',
    url: '/api/v1/organizations/[organization_id]/badge_templates/index_list',
    alwaysRefresh: true,
    list: 'templates'
  });

export const badgeTemplateExportActions =
  (new ActionManager('managementOrganizationsBadgeTemplatesExport'))
    .add({
      requestKey: 'create',
      urlSubs: new stringUtils.BracketSubs({
        organization_id: { param: 'organization_id', remove: true }
      }),
      effect: 'create',
      method: 'POST',
      url: '/api/v1/organizations/[organization_id]/badge_template_export',
      list: 'created',
      alwaysRefresh: true
    })
    .add({
      requestKey: 'get',
      urlSubs: new stringUtils.BracketSubs({
        organization_id: { param: 'organization_id', remove: true },
        id: { param: 'id', remove: true }
      }),
      effect: 'read',
      method: 'GET',
      url: '/api/v1/organizations/[organization_id]/badge_template_export/[id]',
      list: 'read',
      alwaysRefresh: true
    });

export const badgeTemplateSkillExtractionActions = new ActionManager('badgeTemplateSkillExtractions')
  .add({
    requestKey: 'create',
    effect: 'create',
    method: 'POST',
    url: '/api/v1/users/self/badge_template_skill_extractions'
  })
  .add({
    requestKey: 'getStatus',
    urlSubs: new stringUtils.BracketSubs({
      id: { param: 'id', remove: true }
    }),
    effect: 'read',
    method: 'GET',
    url: '/api/v1/users/self/badge_template_skill_extractions/[id]/status',
    alwaysRefresh: true
  }, true);

export const badgeTemplateHRISMapActions = (new ActionManager('managementOrganizationsBadgeTemplatesHRISObjectId'))
  .add({
    requestKey: 'get',
    list: 'list',
    urlSubs: new stringUtils.BracketSubs({
      organization_id: { param: 'organization_id', remove: true },
      badge_template_id: { param: 'badge_template_id', remove: true }
    }),
    effect: 'read',
    method: 'GET',
    url: '/api/v1/organizations/[organization_id]/badge_templates/[badge_template_id]/hris_mappings/Cornerstone',
    alwaysRefresh: true
  })
  .add({
    requestKey: 'synch',
    alwaysRefresh: true,
    urlSubs: new stringUtils.BracketSubs({
      organization_id: { param: 'organization_id', remove: true },
      badge_template_id: { param: 'badge_template_id', remove: true }
    }),
    effect: 'create',
    method: 'POST',
    url: '/api/v1/organizations/[organization_id]/badge_templates/[badge_template_id]/hris_mappings/Cornerstone/synch'
  });

export const badgeTemplateIssuingActions = (new ActionManager('managementOrganizationsBadgeTemplatesIssuing'))
  .add({
    requestKey: 'issue',
    urlSubs: new stringUtils.BracketSubs({
      organization_id: { param: 'organization_id', remove: true }
    }),
    effect: 'create',
    method: 'POST',
    url: '/api/v1/organizations/[organization_id]/badges',
    alwaysRefresh: true
  });

export const learningOrgsFromPermissionsActions = (new ActionManager('permissionOrganizations'))
  .add({
    requestKey: 'get',
    list: 'read',
    urlSubs: new stringUtils.BracketSubs({
      user_id: { param: 'user_id', remove: true },
      permission: { param: 'permission', remove: true }
    }),
    effect: 'read',
    method: 'GET',
    url: '/api/v1/users/[user_id]/permissions/request/[permission]',
    alwaysRefresh: true,
    // no-op just to suppress the default behavior of reloading the page to prompt for sign-in
    onUnauthorized: () => {}
  });
