import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'controls/dialog';
import { Button } from 'controls/button';
import { OrganizationSelectorCard } from './organization_selector_card';
import { Heading } from 'controls/heading';

import './learning_organizations_selector.sass';
import { LearningRouteHelpers } from 'pages/workforce/route_helpers';
import { FormattedMessage } from "react-intl";


/**
 * A dialog that allows the user to select an organization to recommend a badge to
 *
 * @param {object} props - the props for this component
 * @param organizations - the organizations to display
 * @param onClose - the function to call when the dialog is closed
 * @param show  - whether or not to show the dialog
 * @param badgeTemplateId - the id of the badge template to recommend
 * @returns {JSX.Element}
 */

export const LearningOrganizationsSelector = ({ organizations, onClose, show, badgeTemplateId }) => {
  const orgsSorted = useMemo(() => {
    return organizations.sort((org1, org2) => org1.name.localeCompare(org2.name));
  }, [organizations]);

  return (
    <Dialog
      backdropTheme="light"
      show={show}
      shouldClose={onClose}
      borderTheme="rounded"
      size="auto"
    >
      <div className="c-learning-organizations-modal-selector">
        <Heading className="c-learning-organizations-modal-selector__header" appearance="custom">
          <FormattedMessage
            id="sidebar.learning_orgs_selector.choose_org"
            defaultMessage="Choose Org"
          />
        </Heading>
        <Dialog.Content contentPadTheme="none">
          <div className="c-learning-organizations-modal-selector__org-cards">
            {orgsSorted.map(org => {
              const learningUrl = LearningRouteHelpers.new.parameterize({
                organization_id: org.id,
                templateId: badgeTemplateId
              });

              return (
                <OrganizationSelectorCard
                  key={org.id}
                  organization={org}
                  url={learningUrl}
                  className="c-learning-organizations-modal-selector__org-cards__card"
                />
              );
            })}
          </div>
        </Dialog.Content>
        <div className="c-learning-organizations-modal-selector__buttons">
          <Button
            className="c-learning-organizations-modal-selector__buttons__button"
            onClick={onClose}
            type="cancel"
          >
            <FormattedMessage
              id="sidebar.learning_orgs_selector.cancel"
              defaultMessage="Cancel"
            />
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

LearningOrganizationsSelector.propTypes = {
  organizations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    photo_url: PropTypes.string,
    vanity_slug: PropTypes.string,
    workforce_enabled: PropTypes.bool
  })).isRequired,
  onClose: PropTypes.func,
  show: PropTypes.bool,
  badgeTemplateId: PropTypes.string
};
