import * as Routes from 'utils/routes';
import { defineRoutes } from 'app_utils';

export const WorkforceRouteHelpers = defineRoutes(
  `${Routes.workforceUrl.root()}/organizations/:organization_id`,
  {
    accounts: '/accounts',
    analytics: '/analytics',
    dashboard: '/dashboard',
    employees: '/employees',
    learning: '/learning',
    chartDemo: '/chart_demo'
  }
);

export const EmployeesRouteHelpers = defineRoutes(
  WorkforceRouteHelpers.employees,
  {
    employees: '/employees',
    badges: '/badges',
    occupations: '/occupations',
    skills: '/skills'
  }
);

export const AccountsRouteHelpers = defineRoutes(
  WorkforceRouteHelpers.accounts,
  {
    subscriptions: '/subscriptions'
  }
);

export const SubscriptionsRouteHelpers = defineRoutes(
  AccountsRouteHelpers.subscriptions,
  {
    manage: '/manage/:id',
    empty: '/empty',
    history: '/history',
    new: '/new',
    edit: '/:id/edit'
  }
);

export const LearningRouteHelpers = defineRoutes(
  WorkforceRouteHelpers.learning,
  {
    show: '/learning_plans/:id',
    learningPlans: '/learning_plans',
    new: '/new/:templateId',
    edit: '/edit/:learningPlanId'
  }
);


export const EmployeeRouteHelpers = defineRoutes(
  WorkforceRouteHelpers.employees,
  {
    employees: '/employees',
    showEmployee: '/employees/:id',
    showEmployeeBadges: '/employees/:id/badges',
    showEmployeeSkills: '/employees/:id/skills',
    editEmployee: '/employees/:id/edit',
    employeeAdd: '/employees/new',
    employeeAddSingle: '/employees/new/add_single',
    employeeAddBulk: '/employees/new/add_bulk',
    employeeUpdateBulk: '/employees/new/update_bulk'
  }
);
