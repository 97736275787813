import React from 'react';
import PropTypes from 'prop-types';
import { Routes, makeClassName, uniqueId } from 'utils';
import { Card } from 'controls/card';
import { SmartLink } from 'controls/smart_link';
import { Text } from "controls/text";
import { Visual } from "controls/visual";
import { ConditionalWrapper } from 'controls/conditional_wrapper';
import { BadgeOrTemplateTrackLink } from 'badges/badge_or_template_track_link';
import BadgePlaceholderImage from 'images/badge-placeholder.png';
import { faLayerGroup } from '@fortawesome/pro-regular-svg-icons/faLayerGroup';
import { faTimer } from '@fortawesome/pro-regular-svg-icons/faTimer';
import { faDollarSign } from '@fortawesome/pro-regular-svg-icons/faDollarSign';
import { faAwardSimple } from '@fortawesome/pro-regular-svg-icons/faAwardSimple';
import { RoleButton } from 'aria/role_button';
import { withRouter } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Validators } from 'utils/validators.js';
import './badge_card.sass';

/**
 * @TODO: this selector doesn't belong here? issuer/owner is a required property in badge card.
 * but maybe it shouldn't be?
 **/
const issuer = (badge) => badge?.owner || (badge?.issuer && badge.issuer.entities[0].entity);

const badgeTemplateLinkProps = (badgeTemplate, source) => {
  return {
    to: Routes.badgeTemplateUrl(issuer(badgeTemplate), badgeTemplate),
    target: '_self',
    track: {
      object_id: badgeTemplate.id,
      object_type: 'BadgeTemplate',
      typeSuffix: 'clicked',
      source: source,
      snapshot_json: {
        badge_template_name: badgeTemplate.name,
        badge_template_id: badgeTemplate.id
      }
    }
  };
};

const badgeLinkProps = (badge, source) => ({
  to: Routes.earnedBadgeUrl(badge.id),
  target: '_self',
  track: {
    object_id: badge.id,
    object_type: 'Badge',
    typeSuffix: 'clicked',
    source: source,
    snapshot_json: {
      badge_template_name: badge.name,
      badge_template_id: badge.id
    }
  }
});

const orgLinkProps = (owner) => ({
  action: owner.vanity_url || '',
  target: '_self',
  trackingParams: {
    object_id: owner.id,
    object_type: 'Organization',
    type: 'organization.clicked',
    snapshot_json: {
      organization_name: owner.name,
      organization_id: owner.id,
      href: window.location.href
    }
  }
});

const headerLink = (badge, objectType, sourceTrack = null) => {
  if (objectType === 'Badge') {
    return badgeLinkProps(badge, sourceTrack);
  }
  if (objectType === 'BadgeTemplate') {
    return badgeTemplateLinkProps(badge, sourceTrack);
  }
};

export const BADGE_CARD_IMAGE_DEFAULT_WIDTH = 140;
export const BADGE_CARD_IMAGE_SMALL_WIDTH = 120;

const renderers = {
  largeColumns2: ({
    Visual,
    Body,
    Footer
  }) => (
    <div className="badge-card__row">
      <div className="badge-card__column badge-card__column--visual">
        <Visual className="badge-card__visual--column" width="70"/>
      </div>
      <div className="badge-card__column badge-card__column--long">
        <Body
          className="badge-card__body--column"
          display={({
            Title,
            OrgLink
          }) => (
            <>
              <Title
                tabIndex={-1}
                textProps={{
                  sizeBreakpoints: {
                    sm: 4,
                    xs: 4,
                    default: 3
                  }
                }}
              />
              <OrgLink
                textProps={{
                  sizeBreakpoints: {
                    sm: 4,
                    xs: 4,
                    default: 3
                  }
                }}
              />
            </>
          )}
        />
        <Footer className="badge-card__footer--column"/>
      </div>
    </div>
  ),
  large: ({
    Visual,
    Body,
    Footer
  }) => (
    <>
      <Visual/>
      <Body/>
      <Footer/>
    </>
  ),
  small: ({
    Visual,
    Body
  }) => (
    <>
      <Visual
        className="badge-card__visual--small"
        width={BADGE_CARD_IMAGE_SMALL_WIDTH}
      />
      <Body/>
    </>
  )
};

export const BadgeCardContext = React.createContext();

const BadgeCard = ({
  appearance,
  data,
  type,
  display,
  className,
  tabIndex,
  loading,
  loadingSettings,
  history,
  sourceTrack,
  ...otherProps
}) => {
  let modifier, renderCallback;
  switch (typeof display) {
    case 'function':
      modifier = 'custom';
      renderCallback = display;
      break;
    case 'string':
      modifier = display;
      renderCallback = renderers[modifier];
      break;
    default:
      renderCallback = renderers.default;
  }

  const stripPathBasenameFromURL = (url) => {
    if (Validators.url(url)) {
      const constructedURL = new URL(url);
      return constructedURL.pathname;
    }
    return url;
  };

  const active = !!(tabIndex !== -1 && !loading && data?.name);

  return (
    <ConditionalWrapper
      active={active}
      tagName={RoleButton}
      props={{
        onClick: (e) => {
          // stop propagation if the user is clicking a link or child of a link within the card
          if (e.target.tagName === 'A' || e.target.closest('a')) {
            e.stopPropagation();
          } else {
            // otherwise redirect to the badge URL
            const relativePath = stripPathBasenameFromURL(headerLink(data, type, sourceTrack).to);
            history.push(relativePath);
          }
        },
        className: 'badge-card__link-wrapper',
        'aria-label': data?.name,
        tagName: 'div'
      }}
    >
      <Card
        {...otherProps}
        tabIndex={loading ? -1 : tabIndex}
        className={makeClassName(
          'badge-card',
          modifier && `badge-card--${modifier}`,
          appearance && `badge-card--${appearance}`,
          loading && `badge-card--loading`,
          active && 'badge-card--has-wrapper',
          className
        )}
      >
        <BadgeCardContext.Provider
          value={{
            data,
            type,
            tabIndex,
            loading,
            loadingSettings,
            modifier,
            sourceTrack
          }}
        >
          {(renderCallback({
            Visual: BadgeCard.Visual,
            Body: BadgeCard.Body,
            Footer: BadgeCard.Footer
          }))}
        </BadgeCardContext.Provider>
      </Card>
    </ConditionalWrapper>
  );
};

BadgeCard.displayName = 'BadgeCard';
BadgeCard.propTypes = {
  appearance: PropTypes.oneOf(['related-badges']),
  sourceTrack: PropTypes.string,
  data: PropTypes.shape({
    name: PropTypes.string,
    image_url: PropTypes.string,
    owner: PropTypes.shape({
      name: PropTypes.string.isRequired,
      vanity_url: PropTypes.string
    }),
    time_to_earn: PropTypes.string,
    level: PropTypes.string,
    cost: PropTypes.string,
    vanity_slug: PropTypes.string
  }),
  display: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string
  ]).isRequired,
  type: PropTypes.oneOf(['Badge', 'BadgeTemplate']).isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool,
  loadingSettings: PropTypes.shape({
    title: PropTypes.shape({
      placeholderLineCount: PropTypes.number
    })
  }),
  tabIndex: PropTypes.number,
  // withRouter
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

BadgeCard.defaultProps = {
  display: 'large',
  type: 'BadgeTemplate'
};

const BadgeCardVisual = ({
  className,
  width,
  ...otherProps
}) => {
  const contextValue = React.useContext(BadgeCardContext);
  const {
    data,
    loading,
    tabIndex = 0,
    modifier
  } = {
    ...contextValue,
    ...otherProps
  };

  return (
    <ConditionalWrapper
      tagName={BadgeOrTemplateTrackLink}
      props={data && headerLink(data, contextValue.type, contextValue.sourceTrack)}
      active={!!(tabIndex !== -1 && !loading && data?.name)}
    >
      <Card.Visual
        size={modifier}
        {...otherProps}
        className={makeClassName('badge-card__visual', className)}
      >
        <Visual
          loading={loading}
          value={data?.image_url}
          emptyValue={BadgePlaceholderImage}
          width={width}
          height={width}
          className="badge-card__image"
          alt={data?.name}
          shape="badge"
        />
      </Card.Visual>
    </ConditionalWrapper>
  );
};

BadgeCardVisual.defaultProps = {
  width: BADGE_CARD_IMAGE_DEFAULT_WIDTH
};

BadgeCardVisual.displayName = 'BadgeCard.Visual';
BadgeCardVisual.propTypes = {
  className: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  data: PropTypes.shape({
    image_url: PropTypes.string
  })
};

BadgeCard.Visual = BadgeCardVisual;

const BadgeCardTitle = ({
  textProps,
  ...props
}) => {
  const contextValue = React.useContext(BadgeCardContext);
  const {
    data,
    loading,
    tabIndex,
    className
  } = {
    ...contextValue,
    ...props
  };

  const cTextProps = {
    ...textProps,
    loadingSettings: {
      lineCount: 2,
      ...textProps?.loadingSettings
    }
  };

  return (
    <ConditionalWrapper
      tagName={BadgeOrTemplateTrackLink}
      props={data && headerLink(data, contextValue.type, contextValue.sourceTrack)}
      active={!!(tabIndex !== -1 && !loading && data?.name)}
    >
      <Text
        {...cTextProps}
        loading={loading}
        appearance="title"
        size={2}
        display="block"
        nowrap
        className={makeClassName('badge-card__title', className)
        }
      >
        {data?.name}
      </Text>
    </ConditionalWrapper>
  );
};

BadgeCardTitle.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.shape({
    name: PropTypes.string
  }),
  textProps: PropTypes.shape(Text.propTypes),
  className: PropTypes.string
};

BadgeCardTitle.displayName = 'BadgeCard.Title';
BadgeCard.Title = BadgeCardTitle;

const BadgeCardOrgLink = ({
  textProps,
  ...props
}) => {
  const contextValue = React.useContext(BadgeCardContext);
  const {
    data,
    loading,
    tabIndex,
    className
  } = {
    ...contextValue,
    ...props
  };

  const bIssuer = data && issuer(data);

  return (
    <ConditionalWrapper
      tagName={SmartLink}
      props={bIssuer && {
        ...orgLinkProps(bIssuer),
        className: 'badge-card__org-link'
      }}
      active={!!(tabIndex !== -1 && !loading && bIssuer)}
    >
      <Text
        {...textProps}
        loading={loading}
        size={2}
        display="block"
        nowrap
        loadingSettings={{
          className: 'badge-card__org-text-placeholder'
        }}
        className={
          makeClassName('badge-card__org-text', className)
        }
      >
        {bIssuer?.name}
      </Text>
    </ConditionalWrapper>
  );
};

BadgeCardOrgLink.propTypes = {
  data: PropTypes.shape({
    owner: PropTypes.shape({
      name: PropTypes.string.isRequired,
      vanity_url: PropTypes.string
    })
  }),
  textProps: PropTypes.shape(Text.propTypes),
  className: PropTypes.string
};

BadgeCardOrgLink.displayName = 'BadgeCard.OrgLink';
BadgeCard.OrgLink = BadgeCardOrgLink;

const BadgeCardBody = ({
  className,
  display,
  ...otherProps
}) => {
  return (
    <Card.Body
      {...otherProps}
      className={makeClassName('badge-card__body', className)}
    >
      {display({
        Title: BadgeCard.Title,
        OrgLink: BadgeCard.OrgLink
      })}
    </Card.Body>
  );
};

BadgeCardBody.displayName = 'BadgeCard.Body';
BadgeCardBody.propTypes = {
  className: PropTypes.string,
  display: PropTypes.func.isRequired
};

// assuming the visual will provide a link the current badge
// let's hide the Title link from the screenreader with -1 tabindex
// to avoid redundancy

BadgeCardBody.defaultProps = {
  display: ({
    Title,
    OrgLink
  }) => (
    <>
      <Title tabIndex={-1}/>
      <OrgLink/>
    </>
  )
};

BadgeCard.Body = BadgeCardBody;

const BADGE_FEATURES = [
  {
    name: 'cost',
    icon: faDollarSign,
    alt: {
      id: 'badge_card.badge_features.cost',
      defaultMessage: 'Cost'
    }
  },
  {
    name: 'level',
    icon: faLayerGroup,
    alt: {
      id: 'badge_card.badge_features.level',
      defaultMessage: 'Level'
    }
  },
  {
    name: 'time_to_earn',
    icon: faTimer,
    alt: {
      id: 'badge_card.badge_features.time_to_earn',
      defaultMessage: 'Time to earn badge'
    }
  },
  {
    name: 'type_category',
    icon: faAwardSimple,
    alt: {
      id: 'badge_card.badge_features.type_category',
      defaultMessage: 'Badge category'
    }
  }
];

const BadgeCardFooter = ({
  className,
  children,
  ...otherProps
}) => {
  const {
    data,
    loading,
    tabIndex = 0
  } = React.useContext(BadgeCardContext);
  // allow props to override any value from context.
  const props = {
    data,
    loading,
    tabIndex, ...otherProps
  };
  const tagsKey = React.useMemo(() => uniqueId().toString(), []);
  const currentTabIndex = React.useMemo(() => loading ? -1 : tabIndex, [loading, tabIndex]);
  const tagValues = React.useMemo(
    () => (
      BADGE_FEATURES
        .map((feat) => props.data && props.data[feat.name])
        .filter((val) => !!val)
    ),
    [props.data]
  );
  const intl = useIntl();

  return (
    <Card.Footer
      {...otherProps}
      className={makeClassName('badge-card__footer', className)}
    >
      {children}
      <Card.Tags
        role="list"
        aria-label={intl.formatMessage({
          id: 'badge_card.badge_features',
          defaultMessage: 'Badge Features'
        })}
        tabIndex={tagValues.length > 0 ? currentTabIndex : -1}
      >
        {BADGE_FEATURES.map((tag) => {
          const tagValue = props.data && props.data[tag.name];
          const visible = props.data && props.data.enable_detail_attribute_visibility;
          const tagText = tagValue || '--';
          const tagAlt = intl.formatMessage(tag.alt);
          const tagProps = {
            role: 'listitem',
            tabIndex: tagValue ? currentTabIndex : -1,
            'aria-hidden': loading,
            'aria-label': `${tagAlt}: ${tagText}`,
            className: `badge-card__tag--${tag.name}`
          };

          return (
            <Card.Tag
              {...tagProps}
              key={`badge-card-tag-${tag.name}-${tagsKey}`}
            >
              <Card.Tag.Prefix>
                <Card.Tag.Icon icon={tag.icon} alt={tagAlt}/>
              </Card.Tag.Prefix>
              <Text
                size={3}
                loading={loading}
                loadingSettings={{
                  className: 'badge-card__tag-placeholder'
                }}
              >
                {visible && tagValue}
              </Text>
            </Card.Tag>
          );
        })}
      </Card.Tags>
    </Card.Footer>
  );
};

BadgeCardFooter.displayName = 'BadgeCard.Footer';
BadgeCardFooter.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    time_to_earn: PropTypes.string,
    level: PropTypes.string,
    cost: PropTypes.string,
    enable_detail_attribute_visibility: PropTypes.bool
  }),
  loading: PropTypes.bool,
  tabIndex: PropTypes.number,
  children: PropTypes.any
};

BadgeCard.Footer = BadgeCardFooter;

const BadgeCardWithRouter = withRouter(BadgeCard);
const testing = { BadgeCard };
export { BadgeCardWithRouter as BadgeCard, testing };
