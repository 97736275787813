import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeClassName } from 'utils';
import { ClampLines } from 'controls/clamp_lines';
import { Heading, NewHeadingLevel } from 'controls/heading';
import { SizedImage } from 'controls/sized_image';
import { Button } from "controls/button";
import { RoleButton } from 'aria/role_button';
import { BadgeIssuer } from './badge_issuer';
import { BadgeSkills } from './badge_skills';
import { EarningCriteria } from './earning_criteria';
import { Endorsements } from './endorsements';
import { BadgeStandards } from './badge_standards';
import { TemplateAttributes } from './template_attributes';
import { BadgeOrTemplateTrackLink } from './badge_or_template_track_link';
import BadgePlaceholderImage from 'images/badge-placeholder.png';
import { useIntl } from 'react-intl';
import { intlKeyFromValue } from 'translations';
import { findPrimaryIssuer } from './selectors';
import RecommendBadgeButton from 'pages/workforce/learning/shared/recommend_badge_button/recommend_badge_button';

import './full_badge.sass';

/**
 * Renders the complete metadata for the given badge template, including name, description, image,
 * skills, earning criteria (activities), template attributes, and standards (alignments).
 *
 * @property {object} badgeTemplate - the badge template data, in the form returned by the public
 *   badge template endpoints, e.g. GET /org/:org_vanity_slug/badge/:badge_template
 * @property {object} issuer - the badge or template issuer, in a form understood by
 *   {@link BadgeIssuer} (i.e. the same form produced by the standard APIs)
 * @property {boolean} hideAdditionalMetadata - Whether or not to hide additional metadata such as
 * criteria, standards, template attributes, truncate description etc.
 * @property {object} tracking - an object containing base tracking parameters for the primary
 *   object being represented (either a Badge or BadgeTemplate); used to supply stat data for
 *   interactions tracked against the metadata
 * @property {string} tracking.object_id - the ID of the badge or template being presented
 * @property {string} tracking.object_type - the type of object represented ("Badge" or
 *   "BadgeTemplate")
 * @property {object} [tracking.snapshot_json] - additional properties to send with the stat, e.g.
 *   issuer ID, etc.
 */
export const FullBadge = ({
  badgeTemplate,
  issuer,
  tracking,
  hideAdditionalMetadata = false,
  hideEarnThisBadge,
  children
}) => {
  const [truncateDescription, setTruncateDescription] = useState(hideAdditionalMetadata);
  const intl = useIntl();

  const renderAdditionalDetailsLink = () => {
    const url = badgeTemplate.global_activity_url;
    if (url) {
      return (
        <BadgeOrTemplateTrackLink
          className="cr-badges-full-badge__addtl-details"
          to={url}
          target="_blank"
          track={{
            ...tracking,
            typeSuffix: 'additional_information.clicked',
            url: url
          }}
        >
          {intl.formatMessage({ id: 'badge.view.learn_more', defaultMessage: 'Learn more' })}
        </BadgeOrTemplateTrackLink>
      );
    } else {
      return null;
    }
  };

  const renderEarnThisBadgeButton = (className) => {
    const url = badgeTemplate.earn_this_badge_url;
    const earnThisBadgeEnabled = badgeTemplate.enable_earn_this_badge;
    if (url && earnThisBadgeEnabled) {
      return (
        <BadgeOrTemplateTrackLink
          className={makeClassName('cr-badges-full-badge__earn-this-badge', className)}
          to={url}
          target="_blank"
          track={{
            ...tracking,
            typeSuffix: 'earn_this_badge.clicked',
            url: url
          }}
        >
          <Button>
            {intl.formatMessage({ id: 'badge.view.earn_this_badge', defaultMessage: 'Earn this Badge' })}
          </Button>
        </BadgeOrTemplateTrackLink>
      );
    } else {
      return null;
    }
  };

  const renderRecommendThisBadgeButton = (className) => {
    const earnThisBadgeEnabled = badgeTemplate.enable_earn_this_badge;
    if (earnThisBadgeEnabled && badgeTemplate.recipient_type === 'User' && badgeTemplate.public) {
      return (<RecommendBadgeButton className={makeClassName('cr-badges-full-badge__recommend-this-badge', className)} badgeTemplate={badgeTemplate}/>);
    } else {
      return null;
    }
  };

  const renderTemplateAttributes = (className, appearance) => {
    return (
      <TemplateAttributes
        className={className}
        appearance={appearance}
        cost={badgeTemplate.cost}
        level={badgeTemplate.level}
        timeToEarn={badgeTemplate.time_to_earn}
        typeCategory={badgeTemplate.type_category}
      />
    );
  };

  const showDescription = () => setTruncateDescription(false);

  const buildBadgeAltText = () => {
    const { name, type_category: typeCategory, level } = badgeTemplate;
    const altText = [intl.formatMessage({
      id: "badge.view.badge_image_alt_text_name",
      defaultMessage: "{badgeName} badge image"
    }, { badgeName: name })];

    if (typeCategory) {
      altText.push(intl.formatMessage({
        id: intlKeyFromValue(typeCategory, "badge.type"),
        defaultMessage: typeCategory
      }));
    }

    if (level) {
      const intlLevel = intl.formatMessage({
        id: intlKeyFromValue(level, "badge.level"),
        defaultMessage: level
      });

      altText.push(intl.formatMessage({
        id: "badge.view.badge_image_alt_text_level",
        defaultMessage: "{badgeLevel} level"
      }, { badgeLevel: intlLevel }));
    }

    altText.push(intl.formatMessage({
      id: "badge.view.issued_by_with_issuer",
      defaultMessage: "Issued by {issuer}"
    }, { issuer: findPrimaryIssuer(issuer).name }));

    return altText.join('. ');
  };

  return (
    <div className="cr-badges-full-badge row">
      <div className="col-12 col-md-4 cr-badges-full-badge__sidebar">
        <SizedImage
          className="cr-badges-full-badge__img"
          src={badgeTemplate.image_url || BadgePlaceholderImage}
          alt={buildBadgeAltText()}
          width={340}
        />
        {!hideEarnThisBadge && renderEarnThisBadgeButton('hide-mobile')}
        {renderRecommendThisBadgeButton('hide-mobile')}

      </div>
      <div className="cr-badges-full-badge__metadata col-12 col-md-8">
        <div className="cr-badges-full-badge__basic-info">
          <div className="cr-badges-full-badge__head-group">
            <Heading appearance="badge-name-hero">{badgeTemplate.name}</Heading>
            <BadgeIssuer issuer={issuer} tracking={tracking} />
          </div>
          <ClampLines
            attributes={{ className: 'cr-badges-full-badge__description' }}
            lines={truncateDescription ? 3 : 1000}
            overflowNode={
              <RoleButton
                tagName="span"
                className="cr-badges-full-badge__description-more"
                onClick={showDescription}
                tabIndex={0}
              >
                {intl.formatMessage({ id: 'badge.view.more', defaultMessage: 'More...' })}
              </RoleButton>
            }
          >
            {badgeTemplate.description}
          </ClampLines>
          {renderAdditionalDetailsLink()}
          {
            !hideAdditionalMetadata &&
            badgeTemplate.enable_detail_attribute_visibility &&
            renderTemplateAttributes('cr-badges-full-badge__attribute-tag-list')
          }
          {!hideEarnThisBadge && renderEarnThisBadgeButton('hide-tablet hide-desktop')}
          {renderRecommendThisBadgeButton('hide-tablet hide-desktop')}
        </div>
        <NewHeadingLevel>
          <BadgeSkills
            showLinks={badgeTemplate.show_skill_tag_links}
            skills={badgeTemplate.skills}
            tracking={tracking}
          />
          {
            !hideAdditionalMetadata && (
              <>
                <EarningCriteria
                  criteria={badgeTemplate.badge_template_activities}
                  tracking={tracking}
                />
                <BadgeStandards
                  standards={badgeTemplate.alignments}
                  tracking={tracking}
                />
              </>
            )
          }
          {children}
          {
            !hideAdditionalMetadata && (
              <Endorsements
                endorsements={badgeTemplate.endorsements}
                tracking={tracking}
              />
            )
          }
        </NewHeadingLevel>
      </div>
    </div>
  );
};

FullBadge.propTypes = {
  badgeTemplate: PropTypes.object.isRequired,
  children: PropTypes.node,
  issuer: PropTypes.object.isRequired,
  tracking: PropTypes.object,
  hideAdditionalMetadata: PropTypes.bool,
  hideEarnThisBadge: PropTypes.bool
};
