import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { LearningRouteHelpers } from 'pages/workforce/route_helpers';
import { FormattedMessage } from 'react-intl';
import { Button } from 'controls/button';
import {
  LearningOrganizationsSelector
} from 'management/organizations/sidebar/organization_modal_selector/learning_organizations_selector';
import { getProfileAction } from 'profile'
import {
  learningOrgsFromPermissionsActions
} from 'management/organizations/pages/badges/templates/action_creators';


/**
 * Renders a button that allows the user to recommend a badge to an organization.
 * This button is only shown if the user has permission to create learning plans.
 *
 * @property {object} badgeTemplate - the badge template data, in the form returned by the public
 *   badge template endpoints, e.g. GET /org/:org_vanity_slug/badge/:badge_template
 * @param {string} className
 * @returns {JSX.Element|null}
 */
const RecommendBadgeButton = ({ badgeTemplate, className }) => {
  const [currentProfile] = getProfileAction.useAction();
  const [orgsFromPermissionsState, orgsFromPermissions] = learningOrgsFromPermissionsActions.useAction('get');

  useEffect(() => {
    if (
      currentProfile
    ) {
      orgsFromPermissions({
        user_id: currentProfile.resources.id,
        permission: 'LearningPlan.create'
      });
    }
  }, [currentProfile, badgeTemplate]);


  const history = useHistory();
  const [showModal, setShowModal] = useState(false);

  const handleRecommendBadgeClick = () => {
    const orgs = orgsFromPermissionsState.resources;

    if (orgs.length > 1) {
      setShowModal(true);
    } else {
      history.push(
        LearningRouteHelpers.new.parameterize({
          organization_id: orgs[0].id,
          templateId: badgeTemplate.id
        })
      );
    }
  };

  if (orgsFromPermissionsState.resources.length > 0) {
    return (
      <div className={className}>
        <Button
          type="secondary"
          id="recommend-badge-button"
          className="cr-badge-template-details__recommend"
          onClick={handleRecommendBadgeClick}
        >
          <FormattedMessage id="badge.view.recommend_this_badge" defaultMessage="Recommend This Badge" />
        </Button>
        <LearningOrganizationsSelector
          show={showModal}
          onClose={() => setShowModal(false)}
          organizations={orgsFromPermissionsState.resources}
          badgeTemplateId={badgeTemplate.id}
        />
      </div>
    );
  }
  return null;
};

RecommendBadgeButton.propTypes = {
  badgeTemplate: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default RecommendBadgeButton;
